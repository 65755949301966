body {
  height: 100%;
}

.row-button {
  background-color: black !important;
  border-radius: 50px !important;
}

.overlay {
  height: calc(80vh - 145px);
  min-height: 540px;
}

.row-padding {
  padding-left: 5%;
  padding-right: 5%;
}

@media only screen and (min-width: 992px) {
  .midpage {
    padding-left: 20%;
    padding-right: 20%;
  }
  .row-padding {
    padding-left: 25%;
    padding-right: 25%;
  }
  .youMayAlsoLike {
    padding-left: 20%;
    padding-right: 20%;
  }
}
.features {
  padding-left: 20%;
  padding-right: 20%;
}

.site {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.site-content {
  flex: 1;
}

.navbar-bg {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.bg-container {
  background: -webkit-linear-gradient(#dd5e89, #f7bb97) !important;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.australia {
  font-family: "Architects Daughter", cursive;
  background: -webkit-linear-gradient(#dd5e89, #f7bb97);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.close-btn {
  font-size: 30px;
  cursor: pointer;
  transition: transform 0.5s ease 0s;
}
.close-btn:hover {
  transform: rotate(90deg);
}

.item-delete-button {
  font-size: 30px;
  cursor: pointer;
  transition: transform 0.5s ease 0s;
}
.item-delete-button:hover {
  transform: rotate(90deg);
  opacity: 0.8;
}

.nav-cart:hover {
  color: rgb(161, 160, 164);
  transform: scale(1.2);
  -ms-transform: scale(1.2);
  -webkit-transform: scale(1.2);
  transition: 0.3s ease-in-out;
}

.list-image:hover {
  opacity: 0.8;
  cursor: pointer;
}
.navbar-links:hover {
  opacity: 0.8;
  text-decoration: underline !important;
}

.navbar-links {
  font-weight: 900;
  color: darkslategray !important;
}

.dashboard-view {
  padding-left: 10%;
  padding-right: 10%;
}

.inner-buttons-container {
  position: absolute;
  bottom: 0px;
  background-color: white;
  /* height: 205px; */
}

@media only screen and (min-width: 992px) {
  .checkout-row {
    padding-left: 10%;
    padding-right: 10%;
  }

  .content {
    width: calc(100% - 250px);
    padding-left: 20px;
    padding-right: 40px;
    margin-left: 250px;
  }
  .left-panel {
    width: calc(100% - 250px);
    padding-left: 20px;
    padding-right: 40px;
    margin-left: 250px;
  }

  .product_image {
    height: 400px !important;
    width: 300px;
    object-fit: cover;
    overflow: hidden;
  }
}

@media only screen and (min-width: 500px) {
  .star-title {
    font-size: 18px;
    display: inline;
  }
  .star-modal {
    padding: 1rem;
  }
}

@media only screen and (max-width: 499px) {
  .star-font-size {
    font-size: 12px;
  }
  .star-box {
    padding: 15px;
  }
  .review-title {
    font-size: 20px !important;
  }
}

@media only screen and (max-width: 992px) {
  .content {
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
  }
  .left-panel {
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
  }

  .product_image {
    height: 200px !important;
    width: 200px;
    object-fit: cover;
    overflow: hidden;
  }
}

.img-text {
  color: red;
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

.img-container:hover .img-overlay {
  height: 100%;
}

.img-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  width: 100%;
  height: 0;
  transition: 0.5s ease;
}

.img-container {
  position: relative;
  width: 50%;
}
.product-im {
  /* width: 100%; */
  height: auto;
}

.collection {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 1px;
  /* margin: 2em; */
}

.youmayalsolike {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 1px;
}

.product {
  display: grid;
  justify-content: center;
  padding: 5px;
  line-height: 1;
}
.product__image {
  width: 300px;
  height: 350px;
  object-fit: cover;
  border-radius: 5px;
}

.product:hover {
  opacity: 0.8;
  cursor: pointer;
}

.breadcrumb {
  background-color: #fafafa !important;
}

.user_order_item {
  cursor: pointer;
}
.user_order_item:hover {
  background-color: gainsboro;
}

.product_image:hover {
  opacity: 0.8;
  cursor: pointer;
}

.instagram-post {
  transition: all 0.4s ease-in-out;
}

.instagram-post:hover {
  transform: scale(1.1);
}

.instagram-images {
  height: 300px;
  width: 300px;
  object-fit: cover;
  overflow: hidden;
  border-radius: 5px;
}

.insta-div {
  display: grid;
  justify-content: center;
  padding: 5px;
  line-height: 1;
}
.insta-icon {
  position: absolute;
  top: 0;
  right: 0;
  padding-right: 10px;
  padding-top: 5px;
}

.instagram-icon {
  position: fixed;
  z-index: 999;
  bottom: 25px;
  right: 10px;
}

.instagram-tooltip {
  background-color: white !important;
}

.icon-bar {
  z-index: 999;
  position: fixed;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  transition: all 0.3s linear;
}

.icon-bar a {
  display: block;
  text-align: center;
  padding: 16px;
  transition: all 0.3s ease;
  color: white;
  font-size: 20px;
}

.snow-icon {
  animation-name: spin;
  animation-duration: 3000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@-moz-keyframes spin {
  from {
    -moz-transform: rotate(0deg);
  }
  to {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.name-input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: rgb(169, 183, 196) !important;
}

.dropdown .shopDropDown:hover {
  text-decoration: underline;
}

.page-item.active .page-link {
  background-color: #fbcdc3 !important;
  color: white !important;
  border: none;
}

.reviewStars:hover {
  background: black;
  color: white;
  transition: 0.3s ease-in-out;
}

.parent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 480px;
}

.my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-left: -30px; /* gutter size offset */
  width: auto;
}
.my-masonry-grid_column {
  padding-left: 30px; /* gutter size */
  background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column > div {
  /* change div to reference your elements you put in <Masonry> */
  /* background: grey; */
  margin-bottom: 30px;
}

.paddingContainer {
  max-width: 1300px;
}

.admin-review-product-name:hover {
  text-decoration: underline;
  color: rgb(40, 167, 69) !important;
}

.new-label {
  position: absolute;
  top: 10px;
  /* right: 0px; */
  padding: 10px;
  /* left: 16px; */
  cursor: pointer;
  /* padding-left: 10px; */
  background-color: #fbcdc3;
  color: white;
  font-size: 14px;
  font-weight: bold;
}

.top-left {
  position: absolute;
  top: 10px;
  padding: 10px;
  /* left: 16px; */
  cursor: pointer;
  /* padding-left: 10px; */
  background-color: #252525;
  color: white;
  font-size: 14px;
  font-weight: bold;
}

@media only screen and (min-width: 1200px) {
  .page-container {
    max-width: 1200px;
  }
}

.sortable-item {
  display: inline-block;
  cursor: move;
}

.touchable {
  border: none !important;
  cursor: pointer;
  opacity: 1;
  transition: opacity 300ms ease;
}
.touchable:focus {
  outline: none !important;
  box-shadow: none !important;
}

.touched {
  opacity: 0.5;
}
